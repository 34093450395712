<template>
  <h1>Shopping Cart</h1>
  <div v-if="cartItems.length>0">
    <ShoppingCartList @remove-from-cart="removeFromCart($event)" :products="cartItems"/>
  <button class="checkout-button">Proceed to Checkout</button>
  </div>
  <div v-if="cartItems.length===0">
    You currently have no items in your Cart
  </div>
</template>
   
<script>
import ShoppingCartList from '../components/ShoppingCartList.vue';
import axios from 'axios';

export default {
  name: "ShoppingCartPage",
  components: {
    ShoppingCartList,
  },
  data(){
    return {
        cartItems: [],
    }
  },
  methods: {
    async removeFromCart(productId) {
      const response = await axios.delete(`/api/users/12345/cart/${ productId}`);
      const updatedCart = response.data;
      this.cartItems = updatedCart;
    }
  },
  async created(){
    const response = await axios.get('/api/users/12345/cart');
    const cartItems = response.data;
    this.cartItems = cartItems;
  }
}
</script>